import React, {Fragment} from 'react'
import NavigationView from "../components/navigation";
import css from './index.module.css';
import View from "../framework/alto/ui/view";
import ImageView from "../framework/alto/ui/image_view";
import hero from "../images/hero.png";
import "../common_styles/common.css";

const IndexPage = () => {
    return (
        <Fragment>
            <View className={css.underline}/>
            <View className={css.document}>
                <NavigationView/>
            </View>
            <View className={css.fullscreen}>
                <ImageView src={hero} className={css.hero}/>
            </View>
        </Fragment>
    )
}

export default IndexPage
